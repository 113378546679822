.stake-page-wrapper {
  margin-top: 150px;
}

.stake-page-title {
  font-weight: 800 !important;
  font-size: 26px !important;
  font-family: "Outfit", sans-serif !important;
  margin-bottom: 0.2em !important;
  line-height: 1.2em !important;
  text-align: center;
  color: #273852;
}

.stake-page-description {
  font-weight: 500 !important;
  font-family: "Outfit", sans-serif !important;
  color: #7a8aa0 !important;
  margin-bottom: 16px !important;
  font-size: 12px !important;
  line-height: 1.5em !important;
  text-align: center;
}

.stake-page-stake-card {
  border-radius: 20px !important;
  background: white !important;
  box-shadow: none !important;
  padding: 30px;
  text-align: center;
  background: var(--tg-secondary-color) !important;
  border: 1px solid rgba(43, 44, 56, 0.46);
}

.stake-page-stake-amount-wrapper {
  margin-top: 20px !important;
}

.stake-page-max-button {
  padding: 11px 16px !important;
  font-weight: 700 !important;
  font-family: "Outfit", sans-serif !important;
  width: auto;
  line-height: 1em !important;
  font-size: 10px !important;
  border-radius: 6px !important;
  color: #00a3ff !important;
}

.stake-page-stake-button {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  font-size: 16px !important;
  background-color: rgb(0, 163, 255) !important;
  width: 100%;
  height: 56px;
  font-weight: bold !important;
  border-radius: 10px !important;
  color: white !important;
  text-transform: none !important;
}

.stake-page-detail-wrapper {
  display: flex;
  margin: 16px 0px !important;
}

.stake-page-button-wrapper {
  display: flex;
  margin: 16px 0px !important;
}

.stake-page-button-title {
  color: #7a8aa0;
  font-weight: 400 !important;
  font-size: 12px !important;
  font-family: "Outfit", sans-serif !important;
  line-height: 1.6em !important;
  margin: auto 80px auto auto !important;
}

.stake-page-button {
  margin: 0px auto 0px 0px !important;
  width: 200px !important;
  justify-content: center;
}

.stake-page-detail-text {
  color: #7a8aa0;
  font-weight: 400 !important;
  font-size: 12px !important;
  font-family: "Outfit", sans-serif !important;
  line-height: 1.6em !important;
}

.stake-page-detail-multiline-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}

.stake-page-faq-title {
  font-weight: 800 !important;
  font-size: 18px !important;
  line-height: 1.3em !important;
  color: #273852;
  margin-top: 40px !important;
}

.stake-page-faq-item-wrapper {
  margin: 0px !important;
  border-radius: 20px !important;
  position: inherit !important;
  padding: 20px;
  margin-top: 10px !important;
}

.stake-page-faq-item-title {
  font-weight: 800 !important;
  font-size: 14px !important;
  line-height: 1.6em !important;
  color: #273852;
}

.stake-page-faq-item-description {
  padding: 0px;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 1.6em !important;
  color: #7a8aa0;
}

.stake-page-wallet-detail-wrapper {
  background: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 98.23%
  );
  border-radius: 20px;
  margin-bottom: -20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 32px;
  padding-bottom: 52px;
}

.stake-page-wallet-detail-container {
  display: flex;
}

.stake-page-wallet-detail-divider {
  border-top: 1px solid #f2f4f6;
  width: 100%;
  margin: 20px 0px;
}

.stake-page-wallet-detail-sub-container {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
}

.stake-page-wallet-detail-sub-title {
  font-size: 12px !important;
  line-height: 24px !important;
  color: white;
  font-family: "Outfit", sans-serif !important;
}

.stake-page-wallet-detail-sub-description {
  font-size: 18px !important;
  line-height: 1.4rem !important;
  color: white;
  font-family: "Outfit", sans-serif !important;
  font-weight: 800 !important;
}

.stake-page-wallet-detail-sub-code-description {
  font-size: 14px !important;
  line-height: 1.4rem !important;
  color: white;
  font-family: "Outfit", sans-serif !important;
  font-weight: 500 !important;
}

.stake-page-wallet-detail-address {
  font-size: 14px !important;
  line-height: 24px !important;
  color: white;
  font-family: "Outfit", sans-serif !important;
  margin: auto 10px auto auto !important;
}

.stake-page-wallet-detail-wallet-icon {
  font-size: 16px !important;
  line-height: 24px !important;
  color: white;
  margin: auto 0px !important;
}

.stake-page-referral-code-copy-icon {
  font-size: 16px !important;
  line-height: 24px !important;
  color: white;
  margin: -4px 0px auto 10px !important;
  cursor: pointer;
}
