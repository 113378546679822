.home_wrapper {
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.logo-wrapper {
  height: 40px;
  padding: 20px 0px 20px 0px;
  display: flex;
}

.logo {
  height: 100%;
}

.logo-text {
  font-size: 16px !important;
  font-weight: bold !important;
  margin: 13px auto auto 10px !important;
}

.banner-wrapper {
  height: 500px;
  display: flex;
}

.banner {
  margin: auto auto auto 0px;
}

.banner-title {
  font-weight: bold !important;
  color: rgb(39, 56, 82);
}

.banner-text {
  margin-top: 8px !important;
  font-size: 18px !important;
  line-height: 28px !important;
  color: rgb(39, 56, 82);
}

.banner-stake-button {
  margin-top: 24px !important;
  font-size: 16px !important;
  background-color: rgb(0, 163, 255) !important;
  width: 260px;
  height: 64px;
  font-weight: bold !important;
  border-radius: 10px !important;
  color: white !important;
  text-transform: none !important;
}

.statistics-wrapper {
  display: flex;
  margin: 100px 200px -300px 200px;
  justify-content: space-between;
}

.statistics-item-wrapper {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.statistics-item-title {
  font-size: 20px !important;
  font-weight: bold !important;
  line-height: 22px !important;
  text-align: center;
  font-family: "Outfit", sans-serif !important;
}

.statistics-item-content {
  margin-top: 4px;
  font-weight: 700 !important;
  font-size: 30px !important;
  line-height: 36px !important;
  text-align: center;
  color: white;
  font-family: "Outfit", sans-serif !important;
}

.staking-items-wrapper {
  margin-top: 250px;
  margin-bottom: 100px;
}

.staking-items-title {
  font-weight: 700 !important;
  font-size: 36px !important;
  line-height: 50px !important;
  text-align: center;
  color: rgb(39, 56, 82);
}

.staking-items-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 24px;
  gap: 32px;
}

.staking-item-wrapper {
  flex: 1 1 0%;
  align-items: center;
  padding: 32px;
  background-color: var(--tg-secondary-color) !important;
  border: 1px solid rgba(43, 44, 56, 0.46);
  display: flex;
  flex-direction: column;
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 8px 24px !important;
}

.staking-item-icon-wrapper {
  display: flex;
}

.staking-item-upc-icon-wrapper {
  width: 44px;
  height: 44px;
  background-color: white;
  border-radius: 22px;
  margin-left: 20px;
  display: flex;
}

.staking-item-title {
  font-family: "Outfit", sans-serif !important;
  margin-top: 16px !important;
  font-size: 18px !important;
  line-height: 28px !important;
  text-align: center;
  color: white;
  font-weight: bold !important;
}

.staking-item-description {
  flex: 1 1 0%;
  margin-top: 8px !important;
  font-size: 14px !important;
  font-family: "Outfit", sans-serif !important;
  line-height: 22px !important;
  text-align: center;
  color: rgb(122, 138, 160);
}

.staking-item-detail-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 16px;
}

.staking-item-detail-item-wrapper {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 94px;
}

.staking-item-detail-divider {
  width: 2px;
  height: 40px;
  background-color: white;
  opacity: 0.1;
}

.staking-item-apy {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  font-family: "Outfit", sans-serif !important;
  text-align: center;
  color: rgb(97, 183, 95);
}

.staking-item-detail-title {
  font-size: 14px !important;
  font-family: "Outfit", sans-serif !important;
  line-height: 22px !important;
  text-align: center;
  color: rgb(122, 138, 160);
  margin-top: 4px !important;
}

.staking-item-total-staked {
  font-weight: 700 !important;
  font-size: 18px !important;
  font-family: "Outfit", sans-serif !important;
  line-height: 28px !important;
  text-align: center;
  color: white;
}

.staking-item-stake-button {
  margin-top: 24px !important;
  font-size: 16px !important;
  background-color: rgb(0, 163, 255) !important;
  width: 100%;
  height: 56px;
  font-weight: bold !important;
  border-radius: 10px !important;
  color: white !important;
  text-transform: none !important;
  font-family: "Outfit", sans-serif !important;
}
