.referral-page-title {
  font-weight: 800 !important;
  font-size: 26px !important;
  margin-bottom: 0.2em !important;
  line-height: 1.2em !important;
  text-align: center;
  color: #273852;
  margin-top: 10px;
}

.referral-page-description {
  font-weight: 500 !important;
  color: #7a8aa0;
  margin-bottom: 16px !important;
  font-size: 12px !important;
  font-family: "Outfit", sans-serif !important;
  line-height: 1.5em !important;
  text-align: center;
}

.referral-page-history-card {
  margin-top: 20px !important;
  border-radius: 20px !important;
  box-shadow: none !important;
  padding: 30px;
  background: var(--tg-secondary-color) !important;
  border: 1px solid rgba(43 44 56 / 46%);
}

.referral-page-history-card-header {
  display: flex;
}

.referral-page-history-title {
  font-weight: bold !important;
  line-height: 24px !important;
  font-size: 14px !important;
  font-family: "Outfit", sans-serif !important;
  color: white;
  margin: auto 0px !important;
}

.referral-page-history-filter {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.referral-page-history-card-divider {
  border-top: 1px solid #f2f4f6;
  width: 100%;
  height: 0px;
  margin: 20px 0px;
}

.referral-record-wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.referral-record-detail-text {
  font-size: 12px !important;
  line-height: 24px !important;
  font-family: "Outfit", sans-serif !important;
  color: white;
  margin: auto !important;
}

.referral-record-claim-button {
  margin: auto 0px auto auto !important;
  font-size: 14px !important;
  background-color: #44a08d !important;
  font-weight: bold !important;
  border-radius: 10px !important;
  color: white !important;
  text-transform: none !important;
  width: 80px;
  font-family: "Outfit", sans-serif !important;
}

.referral-page-history-load-more-button {
  margin: 10px auto 0px auto !important;
  font-size: 14px !important;
  background-color: white !important;
  border: 1px solid #273852 !important;
  font-weight: bold !important;
  border-radius: 10px !important;
  color: #273852 !important;
  text-transform: none !important;
  width: 120px;
  font-family: "Outfit", sans-serif !important;
}

.referral-page-no-data-text {
  margin: auto !important;
  font-family: "Outfit", sans-serif !important;
  font-size: 18px !important;
}

.referral-page-claimed-text {
  margin: auto !important;
  font-family: "Outfit", sans-serif !important;
  font-size: 18px !important;
}

.referral-record-card {
  background: var(--tg-secondary-color) !important;
  border: 1px solid rgba(43 44 56 / 46%);
  height: 100%;
}

.referral-record-card-content {
  display: flex;
  flex-direction: column;
  padding: 20px !important;
  height: 100%;
}

.referral-record-card-detail-wrapper {
  display: flex;
  margin-bottom: 20px !important;
}

.referral-record-card-detail-center-title {
  color: #7a8aa0;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Outfit", sans-serif !important;
  line-height: 1.6em !important;
  margin: auto !important;
}

.referral-record-card-detail-title {
  color: #7a8aa0;
  font-weight: 400 !important;
  font-size: 12px !important;
  font-family: "Outfit", sans-serif !important;
  line-height: 1.6em !important;
}

.referral-record-card-detail-text {
  color: #7a8aa0;
  font-weight: 400 !important;
  font-size: 12px !important;
  font-family: "Outfit", sans-serif !important;
  line-height: 1.6em !important;
  margin-left: auto !important;
}

.referral-record-card-claim-button {
  margin: auto !important;
  font-size: 14px !important;
  background-color: #44a08d !important;
  font-weight: bold !important;
  border-radius: 10px !important;
  color: white !important;
  text-transform: none !important;
  width: 80px;
  font-family: "Outfit", sans-serif !important;
}
