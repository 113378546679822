.header-wrapper {
  width: 100%;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
}

.header-logo-wrapper {
  display: flex;
  cursor: pointer;
}

.header-logo {
  height: 40px;
}

.header-logo-text {
  font-size: 16px !important;
  font-weight: bold !important;
  margin: 13px auto auto 10px !important;
}

.tab-wrapper {
  margin: 0px 46px;
  display: flex;
  gap: 32px;
}

.tab-item-wrapper {
  cursor: pointer;
  color: var(--lido-color-secondary);
  font-size: 10px;
  line-height: 1.7em;
  font-weight: 800;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  opacity: 1;
  text-decoration: none !important;
}

.tab-item-text {
  font-size: 14px !important;
  margin-left: 10px !important;
  font-weight: bold !important;
}

.tab-item-selected {
  color: #00a3ff;
}

.header-connect-button {
  margin-left: auto;
}
