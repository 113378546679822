/*
  Theme Name: Xeco - ICO & Crypto Landing Page Template
  Support: themegenix@gmail.com
  Description: Xeco - ICO & Crypto Landing Page Template.
  Version: 1.0
*/

/* CSS Index
============================
01. Variable CSS
02. Header
03. Mobile Menu
04. OffCanvas
05. Banner
06. Breadcrumb
07. Contribution
08. Brand
09. Features
10. Chart
11. RoadMap
12. Team
13. Faq
14. Download
15. Document
16. Blog
17. Pagination
18. Contact
19. Footer
20. Preloader
============================
*/

/*=============================
	1. Google Fonts
===============================*/
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&family=Plus+Jakarta+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

/*=============================
	01. Variable CSS
===============================*/
:root {
  --tg-body-font-family: "Outfit", sans-serif;
  --tg-heading-font-family: "Plus Jakarta Sans", sans-serif;
  --tg-primary-color: #44a08d;
  --tg-secondary-color: #0f101e;
  --tg-body-font-color: #92939e;
  --tg-heading-font-color: #ffffff;
  --tg-paragraph-color: #92939e;
  --tg-body-font-size: 16px;
  --tg-body-font-weight: 400;
  --tg-heading-font-weight: 600;
  --tg-body-line-height: 1.62;
  --tg-heading-line-height: 1.2;
  --tg-blue: #0d6efd;
  --tg-indigo: #6610f2;
  --tg-purple: #6f42c1;
  --tg-pink: #d63384;
  --tg-red: #dc3545;
  --tg-orange: #fd7e14;
  --tg-yellow: #ffc107;
  --tg-green: #093637;
  --tg-teal: #20c997;
  --tg-cyan: #0dcaf0;
  --tg-white: #ffffff;
  --tg-black: #010314;
  --tg-gray: #93b2c8;
  --facebook: #3b5998;
  --twitter: #00acee;
  --linkedin: #1976d2;
  --pinterest: #3b5998;
  --youtube: #c4302b;
  --skype: #00aff0;
}
:root {
  scroll-behavior: auto;
}
body {
  font-family: var(--tg-body-font-family) !important;
  font-weight: var(--tg-body-font-weight);
  font-size: var(--tg-body-font-size);
  line-height: var(--tg-body-line-height);
  color: var(--tg-body-font-color);
  font-style: normal;
  background-color: var(--tg-black);
}
img,
.img {
  max-width: 100%;
  transition: all 0.3s ease-out 0s;
}
.f-left {
  float: left;
}
.f-right {
  float: right;
}
.fix {
  overflow: hidden;
}
a,
button {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-decoration: none;
}
a:focus,
.btn:focus,
button:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
  color: var(--tg-primary-color);
  text-decoration: none;
}
a,
button {
  color: var(--tg-primary-color);
  outline: medium none;
  text-decoration: none;
}
.btn:focus,
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--tg-heading-font-family);
  color: var(--tg-heading-font-color);
  margin-top: 0px;
  margin-bottom: 0.7rem;
  font-style: normal;
  line-height: var(--tg-heading-line-height);
  font-weight: var(--tg-heading-font-weight);
  text-transform: inherit;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}
.list-wrap {
  margin: 0px;
  padding: 0px;
}
.list-wrap li {
  list-style: none;
}
p {
  font-size: var(--tg-body-font-size);
  font-weight: var(--tg-body-font-weight);
  line-height: var(--tg-body-line-height);
  color: var(--tg-paragraph-color);
  margin-bottom: 15px;
}
hr {
  border-bottom: 1px solid var(--tg-primary-color);
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}
label {
  color: var(--tg-body-font-color);
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}
*::-moz-selection {
  background: var(--tg-primary-color);
  color: var(--tg-white);
  text-shadow: none;
}
::-moz-selection {
  background: var(--tg-primary-color);
  color: var(--tg-white);
  text-shadow: none;
}
::selection {
  background: var(--tg-primary-color);
  color: var(--tg-white);
  text-shadow: none;
}
*::-moz-placeholder {
  color: var(--tg-body-font-color);
  font-size: var(--tg-body-font-size);
  opacity: 1;
}
*::placeholder {
  color: var(--tg-body-font-color);
  font-size: var(--tg-body-font-size);
  opacity: 1;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
  background-color: var(--tg-secondary-color);
  border-radius: 10px;
}
::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 8px;
  background-color: var(--tg-secondary-color);
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 99.23%
  );
}
.theme-overlay {
  position: relative;
}
.theme-overlay::before {
  background: var(--tg-primary-color) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}
.separator {
  border-top: 1px solid var(--tg-primary-color);
}

/* Bootstrap 5 */
.container {
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  --bs-gutter-x: 30px;
}
.row.g-0 {
  --bs-gutter-x: 0;
}
.gutter-y-30 {
  --bs-gutter-y: 30px;
}

/*=============================
	1. Button style
===============================*/
.btn {
  user-select: none;
  -moz-user-select: none;
  background: var(--tg-white) none repeat scroll 0 0;
  border: medium none;
  border-radius: 30px;
  color: var(--tg-black);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.42px;
  line-height: 1;
  margin-bottom: 0;
  padding: 18px 45px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  transition: all 0.3s ease 0s;
  vertical-align: middle;
  white-space: nowrap;
}
/* .btn::after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: var(--tg-primary-color);
  line-height: 1;
  margin-left: 40px;
  transition: all 0.3s ease 0s;
} */
.btn:hover {
  background: var(--tg-secondary-color);
  color: var(--tg-white);
}

.card_btn {
  width: auto;
}
.card_btn:hover {
  background: var(--tg-primary-color);
  color: var(--tg-white);
}
.btn:hover::after {
  color: var(--tg-white);
}
.btn.btn-two {
  background: var(--tg-secondary-color);
  color: var(--tg-white);
}
.btn.btn-two::after {
  color: var(--tg-white);
}
.btn.btn-two:hover {
  background: var(--tg-white);
  color: var(--tg-black);
}
.btn.btn-two:hover::after {
  color: var(--tg-primary-color);
}
.breadcrumb > .active {
  color: var(--tg-primary-color);
}

/* scrollUp */
.scroll-top {
  width: 35px;
  height: 35px;
  line-height: 35px;
  position: fixed;
  bottom: -10%;
  right: 30px;
  font-size: var(--tg-body-font-size);
  border-radius: 6px;
  z-index: 9;
  color: var(--tg-white);
  text-align: center;
  cursor: pointer;
  background: var(--tg-primary-color);
  transition: 1s ease;
  border: none;
}
.scroll-top.open {
  bottom: 30px;
}
.scroll-top::after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}
.scroll-top:hover {
  background: var(--tg-secondary-color);
}

/*=============================
	02. Header
===============================*/
.custom-container {
  max-width: 1710px;
}
.transparent-header {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 9;
  height: auto;
}
#header-fixed-height.active-height {
  display: block;
  height: 105px;
}
.menu-area {
  padding: 40px 0 20px;
}
.menu-nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.navbar-wrap {
  display: flex;
  flex-grow: 1;
}
.navbar-wrap ul {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 70px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: var(--tg-black);
  border-radius: 40px;
}
.navbar-wrap > ul > li {
  list-style: none;
  display: block;
  position: relative;
  margin-right: 65px;
}
.navbar-wrap > ul > li:last-child {
  margin-right: 0;
}
.navbar-wrap ul li a {
  color: var(--tg-heading-font-color);
  padding: 24px 0;
  display: block;
  line-height: 1;
  position: relative;
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.96px;
  text-transform: uppercase;
}
.navbar-wrap > ul > li.active > a,
.navbar-wrap > ul > li > a.active,
.navbar-wrap > ul > li:hover > a {
  color: var(--tg-primary-color);
}
.main-menu .navigation li.menu-item-has-children .dropdown-btn {
  display: none;
}
.mobile-menu .navigation .dropdown-btn.open span {
  transform: rotate(180deg);
}
.header-action > ul {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.header-action > ul li {
  position: relative;
  margin-left: 15px;
}
.header-action ul li:first-child {
  margin-left: 0;
}
.header-action ul li a {
  color: var(--tg-heading-font-color);
  font-size: 14px;
}
.header-action .header-login a {
  font-size: 14px;
  display: flex;
  align-items: center;
  background: var(--tg-white);
  color: var(--tg-black);
  font-weight: 700;
  gap: 12px;
  padding: 14px 23px;
  border-radius: 30px;
}
.header-action .header-login a i {
  color: var(--tg-primary-color);
  transition: all 0.3s ease-out 0s;
}
.header-action .header-login a:hover {
  background: var(--tg-primary-color);
  color: var(--tg-white);
}
.header-action .header-login a:hover i {
  color: var(--tg-white);
}
.offcanvas-menu .menu-tigger {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-primary-color);
  color: var(--tg-white);
  border-radius: 50%;
  font-size: 16px;
}
.offcanvas-menu .menu-tigger:hover {
  background: var(--tg-white);
  color: var(--tg-primary-color);
}
.navbar-wrap ul li .sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  min-width: 230px;
  border: 1px solid rgba(43 44 56 / 46%);
  background: var(--tg-secondary-color);
  margin: 0 0;
  transform: scale(1, 0);
  transform-origin: 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 30px 70px 0px rgba(7 8 8 / 15%);
  -moz-box-shadow: 0px 30px 70px 0px rgba(7 8 8 / 15%);
  box-shadow: 0px 30px 70px 0px rgba(7 8 8 / 15%);
  border-radius: 0;
  padding: 18px 0;
  display: block;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
  border-radius: 0 0 10px 10px;
}
.navbar-wrap ul li .sub-menu .sub-menu {
  right: auto;
  left: 100%;
  top: 0;
}
.navbar-wrap ul li .sub-menu li {
  margin-left: 0;
  text-align: left;
  display: block;
}
.navbar-wrap ul li .sub-menu li a {
  padding: 9px 15px 9px 25px;
  color: var(--tg-white);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.96px;
  text-transform: uppercase;
}
.navbar-wrap ul li .sub-menu li.active a,
.navbar-wrap ul li .sub-menu li a.active,
.navbar-wrap ul li .sub-menu li a:hover {
  color: var(--tg-primary-color);
}
.navbar-wrap ul li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.sticky-menu {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  z-index: 99;
  background: var(--tg-secondary-color);
  -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  border-radius: 0;
}
.sticky-menu.menu-area {
  padding: 20px 0;
}

/*=============================
	03. Mobile Menu
===============================*/
.nav-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  display: none;
  color: var(--tg-white);
  margin-right: 30px;
  top: 15px;
}
.nav-logo img {
  max-width: 150px;
  max-height: 38px;
}
.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  z-index: 99;
  border-radius: 0px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -moz-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) e;
  -o-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transform: translateX(101%);
  -ms-transform: translateX(101%);
  transform: translateX(101%);
}
.mobile-menu .navbar-collapse {
  display: block !important;
}
.mobile-menu .nav-logo {
  position: relative;
  padding: 30px 25px;
  text-align: left;
}
.mobile-menu-visible {
  overflow: hidden;
}
.mobile-menu-visible .mobile-menu {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}
.mobile-menu .navigation li.current > a:before {
  height: 100%;
}
.menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
}
.mobile-menu-visible .menu-backdrop {
  opacity: 1;
  visibility: visible;
}
.mobile-menu .menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--tg-secondary-color);
  padding: 0px 0px;
  z-index: 9999;
  box-shadow: -9px 0 14px 0px rgb(0 0 0 / 6%);
}
.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
}
.mobile-menu .close-btn {
  position: absolute;
  right: 15px;
  top: 28px;
  line-height: 30px;
  width: 35px;
  text-align: center;
  font-size: 20px;
  color: var(--tg-primary-color);
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
}
.mobile-menu-visible .mobile-menu .close-btn {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}
.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
  margin: 0;
  padding: 0;
}
.mobile-menu .navigation ul {
  padding: 0;
  margin: 0;
}
.mobile-menu .navigation li {
  position: relative;
  display: block;
  border-top: 1px solid rgb(255 255 255 / 10%);
}
.mobile-menu .navigation:last-child {
  border-bottom: 1px solid rgb(255 255 255 / 10%);
}
.mobile-menu .navigation li > ul > li:first-child {
  border-top: 1px solid rgb(255 255 255 / 10%);
}
.mobile-menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 60px 10px 25px;
  font-size: 14px;
  font-weight: 700;
  color: var(--tg-heading-font-color);
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: none;
  letter-spacing: 1px;
}
.mobile-menu .navigation li ul li > a {
  font-size: 14px;
  margin-left: 20px;
  text-transform: uppercase;
}
.mobile-menu .navigation li ul li ul li a {
  margin-left: 40px;
}
.mobile-menu .navigation li ul li ul li ul li a {
  margin-left: 60px;
}
.mobile-menu .navigation li > a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn {
  position: absolute;
  right: 15px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: var(--tg-white);
  background: var(--tg-primary-color);
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 5;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn i {
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul {
  display: none;
}
.mobile-menu .social-links ul {
  display: flex;
  position: relative;
  text-align: center;
  padding: 30px 20px 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.mobile-menu .social-links li {
  position: relative;
  display: inline-block;
  margin: 0px 6px 10px;
}
.mobile-menu .social-links li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: relative;
  line-height: 32px;
  font-size: 16px;
  color: var(--tg-heading-font-color);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.mobile-menu .social-links li a:hover {
  border-color: var(--tg-primary-color);
  background: var(--tg-primary-color);
  color: var(--tg-white);
}
.menu-area .mobile-nav-toggler {
  position: relative;
  float: right;
  cursor: pointer;
  line-height: 1;
  display: none;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  background: var(--tg-primary-color);
  font-size: 16px;
  border-radius: 50%;
  color: var(--tg-white);
}

/*=============================
	04. OffCanvas
===============================*/
.extra-info {
  background: var(--tg-black) none repeat scroll 0 0;
  height: 100%;
  padding: 30px;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.7s ease 0s;
  width: 340px;
  z-index: 999;
  overflow-y: scroll;
  transform: translateX(100%);
}
.extra-info.active {
  transform: translateX(0);
}
.close-icon {
  margin-top: -16px;
  text-align: right;
}
.close-icon > button {
  background: transparent;
  border: 0 none;
  color: var(--tg-primary-color);
  cursor: pointer;
  font-size: 20px;
  padding: 0;
}
.extra-info .logo-side img {
  max-width: 151px;
}
.side-info {
  border-top: 1px solid var(--tg-primary-color);
  padding-top: 25px;
}
.contact-list h4 {
  color: var(--tg-heading-font-color);
  font-weight: 700;
  font-size: 18px;
}
.contact-list p {
  color: var(--tg-paragraph-color);
  margin: 0;
  margin-bottom: 2px;
  line-height: 26px;
}
.social-icon-right > a {
  color: var(--tg-primary-color);
  display: inline-block;
  margin-right: 20px;
  text-align: center;
}
.social-icon-right > a:hover {
  color: var(--tg-white);
}
.offcanvas-overly {
  position: fixed;
  background: var(--tg-secondary-color);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.offcanvas-overly.active {
  opacity: 0.7;
  visibility: visible;
}
.extra-info::-webkit-scrollbar {
  width: 0px;
}

/*=============================
	05. Banner
===============================*/
.banner-bg {
  background-size: cover;
  background-position: center;
  border-radius: 0 0 20px 20px;
  position: relative;
  padding: 140px 0px 120px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  margin: 0 20px;
}
.banner-bg::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    transparent 65.23%
  );
  z-index: -1;
  border-radius: 0px 0px 20px 20px;
}
.banner-content {
  position: relative;
  z-index: 1;
}
.banner-content .title {
  font-size: 65px;
  margin-bottom: 45px;
  letter-spacing: -2.6px;
}
.banner-content .title span {
  background: linear-gradient(
    180deg,
    var(--tg-white) 0%,
    var(--tg-primary-color) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.banner-content p {
  font-size: 25px;
  margin-bottom: 55px;
  color: var(--tg-white);
  font-weight: 500;
}
.coming-time {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px 75px;
}
.time-count {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: var(--tg-white);
  border-radius: 5px;
  min-width: 110px;
  color: var(--tg-black);
  font-weight: 500;
  font-size: 14px;
  padding: 15px;
  position: relative;
  line-height: 1.4;
}
.time-count span {
  line-height: 0.9;
  font-size: 35px;
  font-weight: 700;
  color: var(--tg-black);
  font-family: var(--tg-heading-font-family);
  display: block;
  margin-bottom: 5px;
}
.time-count::after,
.time-count::before {
  content: "";
  position: absolute;
  right: -35px;
  top: 50%;
  transform: translateY(-50%);
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--tg-white);
  opacity: 0.3;
}
.time-count::after {
  right: -45px;
}
.time-count:last-child:after,
.time-count:last-child:before {
  display: none;
}
.banner-scroll-down {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
}
.banner-scroll-down a {
  color: var(--tg-white);
  display: block;
  width: 30px;
  height: 50px;
  text-align: center;
}
.banner-scroll-down a span {
  display: block;
  width: 15px;
  height: 15px;
  border-bottom: 2px solid var(--tg-white);
  border-right: 2px solid var(--tg-white);
  transform: rotate(45deg);
  margin: -10px 0 0 8px;
  animation: animate 2s infinite;
}
.banner-scroll-down a span:nth-child(2) {
  animation-delay: -0.2s;
}
.banner-scroll-down a span:nth-child(3) {
  animation-delay: -0.4s;
}
.banner-shape-wrap img {
  position: absolute;
}
.banner-shape-wrap img:nth-child(1) {
  left: 107px;
  bottom: 134px;
}
.banner-shape-wrap img:nth-child(2) {
  right: 107px;
  bottom: 120px;
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-15px, -15px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(15px, 15px);
  }
}
@keyframes alltuchtopdown {
  0% {
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateX(0deg) translateY(-30px);
  }
  100% {
    transform: rotateX(0deg) translateY(0px);
  }
}
.alltuchtopdown {
  -webkit-animation-name: alltuchtopdown;
  animation-name: alltuchtopdown;
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes leftToRight {
  0% {
    transform: rotateX(0deg) translateX(0px);
  }
  50% {
    transform: rotateX(0deg) translateX(50px);
  }
  100% {
    transform: rotateX(0deg) translateX(0px);
  }
}
.leftToRight {
  -webkit-animation-name: leftToRight;
  animation-name: leftToRight;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

/*=============================
	06. Breadcrumb
===============================*/
.breadcrumb-bg {
  background-size: cover;
  background-position: center;
  border-radius: 0 0 20px 20px;
  position: relative;
  z-index: 1;
  padding: 270px 0 195px;
  margin: 0 20px;
  overflow: hidden;
}
.breadcrumb-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    transparent 65.23%
  );
  z-index: -1;
}
.breadcrumb-content {
  text-align: center;
}
.breadcrumb-content .title {
  font-size: 60px;
  margin-bottom: 25px;
  letter-spacing: -1.8px;
}
.breadcrumb-content .breadcrumb {
  justify-content: center;
  margin-bottom: 0;
}
.breadcrumb-content .breadcrumb-item {
  color: var(--tg-white);
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.breadcrumb-content .breadcrumb-item a {
  color: #b4b4c3;
}
.breadcrumb-content .breadcrumb-item a:hover {
  color: var(--tg-primary-color);
}
.breadcrumb-content .breadcrumb-item + .breadcrumb-item::before {
  padding-right: 10px;
  color: var(--tg-white);
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  margin-top: 3px;
}
.breadcrumb-content .breadcrumb-item + .breadcrumb-item {
  padding-left: 10px;
}
.breadcrumb-shape-wrap img {
  position: absolute;
  z-index: -1;
}
.breadcrumb-shape-wrap img:nth-child(1) {
  left: 6%;
  top: 42%;
}
.breadcrumb-shape-wrap img:nth-child(2) {
  right: 8%;
  bottom: 22%;
}
.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

/*=============================
	07. Contribution
===============================*/
.contribution-area {
  position: relative;
  background-color: var(--tg-black);
}
.contribution-title .title {
  font-size: 60px;
  text-align: center;
  margin-bottom: 0;
  letter-spacing: -1.8px;
}
.contribution-title {
  margin-bottom: 60px;
}
.contribution-title .title span {
  color: var(--tg-primary-color);
}
.progress-wrap .list-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 160px;
  gap: 30px;
}
.progress-wrap .list-wrap li {
  position: relative;
  color: #aaabb2;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  padding-bottom: 40px;
}
.progress-wrap .list-wrap li::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 17px;
  width: 2px;
  height: 14px;
  background: var(--tg-white);
}
.progress-wrap .progress {
  height: 32px;
  background-color: #1c1d34;
  border-radius: 25px;
  padding: 7px 8px;
  margin-bottom: 20px;
}
.progress-wrap .progress-bar {
  border-radius: 25px;
  background: linear-gradient(
    286deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 98.23%
  );
}
.progress-wrap .progress-title {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  font-family: var(--tg-body-font-family);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.contribution-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin-top: 50px;
  flex-wrap: wrap;
}
.contribution-shape-wrap img {
  position: absolute;
  z-index: -1;
}
.contribution-shape-wrap img:nth-child(1) {
  left: 2%;
  bottom: 25%;
}
.contribution-shape-wrap img:nth-child(2) {
  right: 3%;
  bottom: 23%;
}

/*=============================
	08. Brand
===============================*/
.brand-title {
  margin-bottom: 60px;
}
.brand-title .title {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.26px;
  padding: 0 18px;
}
.brand-title .title::after,
.brand-title .title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--tg-primary-color);
}
.brand-title .title::after {
  left: auto;
  right: 0;
}
.brand-item-wrap {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  padding: 45px 0;
}
.brand-item-wrap::after,
.brand-item-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18%;
  height: 100%;
  background: linear-gradient(90deg, #010314 0%, rgba(1, 3, 20, 0) 100%);
  z-index: 5;
}
.brand-item-wrap::after {
  left: auto;
  right: 0;
  transform: rotate(180deg);
}
.brand-active .col-12 {
  padding: 0 15px;
}
.brand-item {
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.brand-item img {
  opacity: 0.5;
  cursor: pointer;
  transition: 0.3s linear;
}
.brand-item img:hover {
  opacity: 1;
}

/*=============================
	09. Features
===============================*/
.section-title .title {
  background: linear-gradient(180deg, var(--tg-white) 0%, var(--tg-gray) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
  margin-bottom: 0;
  letter-spacing: -1.5px;
}
.features-item {
  display: flex;
  align-items: center;
  background: var(--tg-secondary-color);
  border: 1px solid rgba(43 44 56 / 46%);
  border-radius: 15px;
  padding: 40px 70px 40px 55px;
  margin-bottom: 30px;
}
.features-content {
  width: 50%;
  margin-right: 20px;
}
.features-content .title {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -0.84px;
  margin-bottom: 25px;
}
.features-img {
  flex-grow: 1;
  text-align: right;
}
.features-content p {
  margin-bottom: 0;
}

/* features-two */
.features-bg {
  background-size: cover;
  background-position: center;
  border-radius: 20px 20px 0 0;
  position: relative;
  margin: 0 20px;
  overflow: hidden;
}
.features-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 65.23%
  );
  z-index: -1;
  transform: rotate(180deg);
}
.features-inner-wrap {
  position: relative;
  padding: 140px 0;
  z-index: 1;
}
.features-line-shape {
  width: 100%;
  height: 2px;
  background: linear-gradient(
    225deg,
    #010314 0%,
    rgba(255, 255, 255, 0.45) 54.98%,
    rgba(1, 3, 20, 0.99) 100%
  );
  position: absolute;
  left: 0;
  bottom: 0;
}
.features-item-wrap {
  margin-bottom: 90px;
}
.features-item-two {
  border-radius: 15px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.14) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 0px 16px 18px 0px rgba(1, 12, 50, 0.1);
  display: flex;
  align-items: center;
  padding: 30px 30px;
  margin-bottom: 30px;
  transition: 0.3s linear;
}
.features-item-two:hover {
  transform: translateY(-5px);
}
.features-img-two {
  width: 95px;
  flex: 0 0 auto;
  margin-right: 30px;
}
.features-img-two img {
  height: auto;
}
.features-content-two .title {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.72px;
}
.section-title .title img {
  border-radius: 50px;
  margin: 0 5px;
}
.section-title-two.section-title .title {
  font-size: 60px;
  background: linear-gradient(
    180deg,
    var(--tg-white) 0%,
    var(--tg-primary-color) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.features-shape-wrap img {
  position: absolute;
}
.features-shape-wrap img:nth-child(1) {
  left: 15%;
  bottom: 6%;
}
.features-shape-wrap img:nth-child(2) {
  right: 9%;
  bottom: 0%;
}

/*=============================
	10. Chart
===============================*/
.chart-inner-wrap {
  position: relative;
}
.chart-inner-wrap::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(
    225deg,
    var(--tg-black) 0%,
    rgba(255, 255, 255, 0.45) 54.98%,
    rgba(1, 3, 20, 0.99) 100%
  );
}
.chart-wrap {
  margin-left: 30px;
}
.chart-wrap .chart {
  width: 340px;
  height: 340px;
  margin: 0 0 50px 60px;
}
.chart-tab .nav-tabs {
  border-bottom: none;
  display: inline-flex;
  background: #1c1d34;
  border-radius: 30px;
  padding: 10px 10px;
  justify-content: center;
  margin-bottom: 50px;
}
.chart-tab .nav-tabs .nav-link {
  margin-bottom: 0;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 700;
  border-radius: 30px;
  color: var(--tg-white);
  padding: 8px 35px;
}
.chart-tab .nav-tabs .nav-link.active {
  background: var(--tg-white);
  color: #010314;
}
.chart-list .list-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px 0;
}
.chart-list .list-wrap li {
  color: var(--tg-white);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.48px;
  width: 50%;
  position: relative;
  padding-left: 30px;
}
.chart-list .list-wrap li::before {
  content: "";
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: #44a08d;
  position: absolute;
  left: 0;
  top: 3px;
}
.chart-list .list-wrap li:nth-child(2):before {
  background: #033356;
}
.chart-list .list-wrap li:nth-child(3):before {
  background: #136f84;
}
.chart-list .list-wrap li:nth-child(4):before {
  background: #012641;
}
.chart-list .list-wrap li:nth-child(5):before {
  background: #0b446d;
}
.chart-list .list-wrap li:nth-child(6):before {
  background: #191f28;
}
.chart-inner-wrap .right-side-content {
  margin-left: 190px;
}
.chart-inner-wrap .right-side-content img {
  margin-bottom: 30px;
}
.chart-inner-wrap .right-side-content p {
  margin-bottom: 25px;
  margin-left: 20px;
}
.chart-inner-wrap .right-side-content .list-wrap li {
  display: flex;
  font-size: 18px;
  font-weight: 500;
  color: var(--tg-white);
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  padding: 9px 20px;
  transition: 0.3s ease-in-out;
}
.chart-inner-wrap .right-side-content .list-wrap li span {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 10%);
  color: var(--tg-white);
  border-radius: 50%;
}
.chart-inner-wrap .right-side-content .list-wrap li:hover {
  background: linear-gradient(
    82deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 98.23%
  );
}
.chart-inner-wrap .right-side-content .list-wrap li:hover span {
  background: var(--tg-black);
}

/*=============================
	11. RoadMap
===============================*/
.roadmap-area {
  overflow: hidden;
}
.roadmap-title {
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 45px;
  margin-left: 14px;
  letter-spacing: 0.56px;
  text-transform: uppercase;
}
.roadmap-content {
  border: 1px solid rgba(43 44 56 / 46%);
  background: var(--tg-secondary-color);
  border-radius: 15px;
  padding: 75px 50px 65px;
}
.roadmap-content .title {
  display: flex;
  align-items: flex-start;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 32px;
  gap: 16px;
}
.roadmap-content .title .dot {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: block;
  position: relative;
  background: rgba(255 255 255 / 11%);
  flex: 0 0 auto;
  transform: translateY(3px);
}
.roadmap-content .title .dot::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 98.23%
  );
}
.roadmap-content .title .dot::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 24px;
  width: 1px;
  height: 120px;
  background: #292a37;
}
.roadmap-content p {
  margin-bottom: 0;
}
.roadMap-active .col-lg-4 {
  padding: 0 15px;
}

/*=============================
	12. Team
===============================*/
.team-bg {
  background-size: cover;
  background-position: center;
  border-radius: 0 0 20px 20px;
  position: relative;
  padding: 140px 0;
  overflow: hidden;
  margin: 0 20px;
}
.team-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 91.23%
  );
  z-index: -1;
}
.team-item {
  text-align: center;
}
.team-thumb {
  position: relative;
  margin-bottom: 15px;
}
.team-thumb img {
  mix-blend-mode: luminosity !important;
  display: inline-block !important;
}
.team-thumb .team-social {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-white);
  border-radius: 50%;
  font-size: 15px;
}
.team-thumb .team-social:hover {
  background: var(--tg-primary-color);
  color: var(--tg-white);
}
.team-content .title {
  font-size: 22px;
  margin-bottom: 15px;
}
.team-content span {
  line-height: 1;
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #cbd0d4;
}

/*=============================
	13. Faq
===============================*/
.faq-area {
  background-color: var(--tg-black);
}

.faq-inner-wrap {
  padding: 140px 0;
  position: relative;
}
.faq-inner-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    225deg,
    #010314 0%,
    rgba(255, 255, 255, 0.45) 54.98%,
    rgba(1, 3, 20, 0.99) 100%
  );
}
.faq-wrap {
  margin: 0 30px;
}
.faq-wrap .accordion-item {
  background-color: var(--tg-secondary-color);
  border: 1px solid rgba(43 44 56 / 46%);
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 20px;
}
.faq-wrap .accordion-item.active {
  background: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 98.23%
  );
  border: none;
}
.faq-wrap .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-wrap .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.faq-wrap .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.faq-wrap .accordion-button {
  padding: 38px 90px 38px 55px;
  font-size: 24px;
  color: var(--tg-white);
  background-color: transparent;
  font-weight: 600;
  letter-spacing: -0.48px;
  font-family: var(--tg-heading-font-family);
  position: relative;
}
.faq-wrap .accordion-button::after {
  flex-shrink: 0;
  width: auto;
  height: auto;
  content: "\f062";
  background-image: none;
  font-size: 25px;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  position: absolute;
  right: 45px;
  top: 38px;
  color: var(--tg-white);
  opacity: 0.3;
}
.faq-wrap .accordion-button:not(.collapsed)::after {
  background-image: none;
  opacity: 1;
}
.faq-wrap .accordion-button:not(.collapsed) {
  color: var(--tg-white);
  background-color: transparent;
  box-shadow: none;
}
.faq-wrap .accordion-button:focus {
  border-color: none;
  box-shadow: none;
}
.faq-wrap .accordion-body {
  padding: 0 55px 70px;
}
.faq-wrap .accordion-body p {
  font-size: 18px;
  margin-bottom: 0;
  color: #cbd0d4;
  white-space: pre-line;
}

/*=============================
	14. Download
===============================*/
.download-inner-wrap {
  background: var(--tg-secondary-color);
  border-radius: 15px;
  border: 1px solid rgba(43 44 56 / 46%);
  padding: 75px 80px 0;
  position: relative;
  z-index: 1;
}
.download-content {
  padding: 40px 0 115px;
}
.download-list .list-wrap {
  display: flex;
  align-items: center;
  gap: 15px 50px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.download-list .list-wrap li {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: var(--tg-white);
  gap: 10px;
}
.download-list .list-wrap li span {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  background: #1a1c2b;
  border-radius: 50%;
  box-shadow: 0px 1px 4px 0px rgba(0, 20, 90, 0.1);
  font-size: 16px;
}
.download-btn-wrap {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.download-btn-wrap .download-btn img {
  max-height: 60px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: auto;
  height: auto;
}
.download-img {
  position: relative;
  z-index: 1;
  text-align: right;
}
.download-img img {
  height: auto;
}
.download-img img:nth-child(2) {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.download-shape-wrap img {
  position: absolute;
  z-index: -1;
  height: auto;
}
.download-shape-wrap img:nth-child(1) {
  left: 0;
  bottom: 0;
}
.download-shape-wrap img:nth-child(2) {
  right: 0;
  bottom: 0;
}

/*=============================
	15. Document
===============================*/
.document-area {
  position: relative;
  z-index: 2;
}
.document-inner-wrap .row {
  --bs-gutter-x: 20px;
}
.document-form-wrap {
  background: var(--tg-secondary-color);
  border: 1px solid rgba(43 44 56 / 46%);
  padding: 60px 50px 50px;
  text-align: center;
  border-radius: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.document-form-wrap .title {
  font-size: 28px;
  margin-bottom: 35px;
}
.document-form-wrap .row {
  --bs-gutter-x: 20px;
}
.document-form-wrap .form-grp {
  margin-bottom: 20px;
}
.document-form-wrap .form-grp textarea,
.document-form-wrap .form-grp select,
.document-form-wrap .form-grp input {
  width: 100%;
  background: rgba(255 255 255 / 7%);
  border: none;
  border-radius: 30px;
  font-size: 16px;
  color: var(--tg-white);
  padding: 15px 20px;
  line-height: 1;
  height: 50px;
  display: block;
}

.document-form-wrap .form-grp select {
  appearance: none;
}

.document-form-wrap .form-grp select option {
  background-color: #0f101e;
}

,
.document-form-wrap .form-grp textarea::placeholder,
.document-form-wrap .form-grp input::placeholder {
  font-size: 16px;
  color: #aaabb2;
  line-height: 1;
}
.document-form-wrap .form-grp textarea {
  min-height: 200px;
  max-height: 200px;
}
.document-form-wrap form .btn {
  margin-top: 15px;
}
.document-wrap .btn:hover,
.document-form-wrap form .btn:hover {
  background: var(--tg-primary-color);
}
.document-wrap {
  background: var(--tg-secondary-color);
  border: 1px solid rgba(43 44 56 / 46%);
  padding: 60px 64px 50px;
  border-radius: 15px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.document-wrap .title {
  margin-bottom: 35px;
  font-size: 28px;
}
.document-wrap .list-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 25px;
}
.document-wrap .list-wrap a {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--tg-white);
}
.document-wrap .list-wrap a:hover {
  color: var(--tg-primary-color);
}
.document-wrap .list-wrap a .icon {
  width: 130px;
  height: 104px;
  background: rgba(255 255 255 / 7%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--tg-white);
  font-size: 35px;
  line-height: 0;
  margin-bottom: 5px;
}
.document-shape img {
  position: absolute;
  right: 3%;
  top: -2%;
  z-index: -1;
}

/*=============================
	16. Blog
===============================*/
.blog-masonry-post {
  display: flex;
  background: var(--tg-secondary-color);
  padding: 20px 40px 20px 20px;
  border-radius: 30px;
  border: 1px solid rgba(43 44 56 / 46%);
  margin-bottom: 60px;
}
.blog-masonry-thumb {
  width: 315px;
  flex: 0 0 auto;
  margin-right: 30px;
}
.blog-masonry-thumb a {
  height: 100%;
}
.blog-masonry-thumb img {
  border-radius: 30px;
  mix-blend-mode: luminosity;
  height: 100%;
  object-fit: cover;
}
.blog-meta .list-wrap {
  display: flex;
  align-items: center;
  gap: 15px 50px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.blog-meta .list-wrap li {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.blog-meta .list-wrap li i {
  color: var(--tg-white);
  font-size: 16px;
  margin-right: 5px;
}
.blog-meta .list-wrap li a {
  display: flex;
  align-items: center;
  color: var(--tg-paragraph-color);
}
.blog-meta .list-wrap li a:hover {
  color: var(--tg-primary-color);
}
.blog-masonry-content .title {
  margin-bottom: 30px;
  font-size: 28px;
  letter-spacing: -0.84px;
  width: 90%;
}
.blog-masonry-content .title a {
  display: inline;
  background-image: linear-gradient(var(--tg-white), var(--tg-white)),
    linear-gradient(var(--tg-white), var(--tg-white));
  background-size: 0% 1.5px, 0 1.5px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.blog-masonry-content .title a:hover {
  color: var(--tg-white);
  color: inherit;
  background-size: 0 1.5px, 100% 1.5px;
}
.blog-masonry-content p {
  margin-bottom: 55px;
  width: 90%;
}
.blog-masonry-content .content-bottom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}
.blog-author a {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--tg-white);
}
.blog-author a img {
  border-radius: 50%;
  width: 40px;
  flex: 0 0 auto;
  margin-right: 20px;
}
.blog-author a:hover {
  color: var(--tg-primary-color);
}
.blog-post-wrap .read-more-btn {
  margin-left: auto;
}
.blog-post-wrap .read-more-btn a {
  font-size: 16px;
  color: var(--tg-secondary-color);
  background: var(--tg-white);
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  gap: 10px;
  padding: 7px 29px;
}
.blog-post-wrap .read-more-btn a i {
  transform: rotate(-45deg);
  transition: all 0.3s ease-out 0s;
}
.blog-post-wrap .read-more-btn a:hover {
  background: var(--tg-primary-color);
  color: var(--tg-white);
}
.blog-post-wrap .read-more-btn a:hover i {
  transform: rotate(0);
}
.blog-standard-post {
  background: var(--tg-secondary-color);
  padding: 20px 20px 60px 20px;
  border-radius: 30px;
  border: 1px solid rgba(43 44 56 / 46%);
  margin-bottom: 60px;
}
.blog-standard-thumb {
  margin-bottom: 30px;
}
.blog-standard-thumb img {
  border-radius: 30px;
  mix-blend-mode: luminosity;
  width: 100%;
  height: auto;
}
.blog-standard-content {
  padding: 0 120px 0 40px;
}
.blog-meta .list-wrap .blog-author a {
  font-size: 16px;
  font-weight: 500;
  color: var(--tg-white);
}
.blog-standard-content .title {
  font-size: 28px;
  letter-spacing: -0.84px;
  margin-bottom: 30px;
}
.blog-standard-content .title a {
  display: inline;
  background-image: linear-gradient(var(--tg-white), var(--tg-white)),
    linear-gradient(var(--tg-white), var(--tg-white));
  background-size: 0% 1.5px, 0 1.5px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.blog-standard-content .title a:hover {
  color: var(--tg-white);
  color: inherit;
  background-size: 0 1.5px, 100% 1.5px;
}
.blog-standard-content p {
  margin-bottom: 55px;
}

/* blog-sidebar */
.blog-widget {
  border-radius: 20px;
  border: 1px solid rgba(43 44 56 / 46%);
  background: var(--tg-secondary-color);
  padding: 45px 40px 50px;
  margin-bottom: 60px;
}
.blog-widget:last-child {
  margin-bottom: 0;
}
.widget-title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.72px;
  margin-bottom: 30px;
}
.sidebar-search form {
  position: relative;
}
.sidebar-search form input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #292a37;
  background: transparent;
  font-size: 18px;
  line-height: 1;
  padding: 18px 55px 18px 10px;
  height: 60px;
  color: var(--tg-white);
}
.sidebar-search form input::placeholder {
  font-size: 18px;
  color: var(--tg-paragraph-color);
}
.sidebar-search form button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: transparent;
  font-size: 18px;
  padding: 0;
  line-height: 1;
  color: var(--tg-paragraph-color);
}
.sidebar-search form button:hover {
  color: var(--tg-white);
}
.sidebar-cat-list .list-wrap li a {
  font-size: 18px;
  font-weight: 500;
  color: var(--tg-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 14px;
  margin-bottom: 14px;
  position: relative;
}
.sidebar-cat-list .list-wrap li a:hover {
  color: var(--tg-paragraph-color);
}
.sidebar-cat-list .list-wrap li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background: #292a37;
  transition: all 0.3s ease-out 0s;
}
.sidebar-cat-list .list-wrap li a:hover::before {
  width: 100%;
}
.sidebar-cat-list .list-wrap li:last-child a {
  margin: 0;
  padding: 0;
}
.sidebar-cat-list .list-wrap li:last-child a::before {
  display: none;
}
.rc-post-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}
.rc-post-item:last-child {
  margin-bottom: 0;
}
.rc-post-item .thumb {
  width: 80px;
  flex: 0 0 auto;
  margin-right: 20px;
}
.rc-post-item .thumb img {
  border-radius: 5px;
  mix-blend-mode: luminosity;
}
.rc-post-item .date {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: var(--tg-white);
  margin-bottom: 15px;
}
.rc-post-item .title {
  margin-bottom: 0;
  font-size: 18px;
  font-family: var(--tg-body-font-family);
  font-weight: 400;
  color: var(--tg-paragraph-color);
}
.sidebar-follow-wrap .list-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #292a37;
  padding-bottom: 40px;
  margin-bottom: 40px;
}
.sidebar-follow-wrap .list-wrap li a {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #292a37;
  border-radius: 50%;
  color: var(--tg-white);
}
.sidebar-follow-wrap .list-wrap li a:hover {
  background: var(--tg-white);
  border-color: var(--tg-white);
  color: var(--tg-secondary-color);
}
.sidebar-follow-wrap .btn:hover {
  background: var(--tg-primary-color);
}
.sidebar-tag-list .list-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.sidebar-tag-list .list-wrap li a {
  color: rgba(146, 147, 158, 0.5);
  font-size: 14px;
  letter-spacing: 0.7px;
  border: 1px solid rgba(43 44 56 / 46%);
  display: block;
  padding: 7px 18px;
  line-height: 1;
  border-radius: 3px;
}
.sidebar-tag-list .list-wrap li a:hover {
  background: var(--tg-white);
  color: var(--tg-secondary-color);
  border-color: var(--tg-white);
}

/* blog-details */
.blog-details-thumb {
  margin-bottom: 30px;
}
.blog-details-thumb img {
  height: 420px;
  object-fit: cover;
  border-radius: 30px;
  mix-blend-mode: luminosity;
  width: 100%;
}
.blog-details-content {
  margin-bottom: 90px;
}
.blog-details-content .title {
  margin-bottom: 30px;
  font-size: 28px;
}
.blog-details-content > p {
  margin-bottom: 25px;
}
.blog-details-content blockquote {
  background: var(--tg-secondary-color);
  border-radius: 15px;
  border: 1px solid rgba(43 44 56 / 46%);
  padding: 40px 40px;
  margin: 50px 0 50px 0;
}
.blog-details-content blockquote p {
  font-size: 24px;
  font-weight: 600;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-white);
  margin-bottom: 15px;
}
.blog-details-content blockquote cite {
  font-style: normal;
  font-size: 16px;
}
.blog-details-inner-img {
  margin-bottom: 20px;
}
.blog-details-inner-img img {
  border-radius: 30px;
  height: 290px;
  object-fit: cover;
  mix-blend-mode: luminosity;
  margin-bottom: 30px;
}
.blog-details-bottom {
  margin-top: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid #292a37;
}
.post-tags .list-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.post-tags .list-wrap li a {
  color: rgba(146, 147, 158, 0.5);
  font-size: 14px;
  letter-spacing: 0.7px;
  border: 1px solid rgba(43 44 56 / 46%);
  display: block;
  padding: 7px 18px;
  line-height: 1;
  border-radius: 3px;
}
.post-tags .list-wrap li a:hover {
  background: var(--tg-white);
  color: var(--tg-secondary-color);
  border-color: var(--tg-white);
}
.blog-post-share .list-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.blog-post-share .list-wrap li a {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #292a37;
  font-size: 12px;
  color: var(--tg-white);
}
.blog-post-share .list-wrap li a:hover {
  background: var(--tg-white);
  color: var(--tg-secondary-color);
  border-color: var(--tg-white);
}
.blog-avatar-wrap {
  border: 1px solid rgba(43 44 56 / 46%);
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  padding: 42px 42px;
  background: var(--tg-secondary-color);
}
.blog-avatar-img {
  width: 80px;
  flex: 0 0 auto;
  margin-right: 30px;
}
.blog-avatar-img img {
  border-radius: 50%;
}
.blog-avatar-info .name {
  font-size: 20px;
  margin-bottom: 15px;
}
.blog-avatar-info p {
  margin-bottom: 0;
}
.comments-wrap {
  margin-bottom: 90px;
}
.comments-wrap-title {
  font-size: 28px;
  margin-bottom: 50px;
  letter-spacing: -0.84px;
}
.comments-box {
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px;
}
.comments-avatar {
  width: 70px;
  flex: 0 0 auto;
  margin-right: 30px;
}
.comments-text .avatar-name {
  margin-bottom: 20px;
}
.comments-text .avatar-name .name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 18px;
}
.comments-text .avatar-name .name .reply-btn {
  font-size: 12px;
  background: var(--tg-secondary-color);
  border: 1px solid rgba(43 44 56 / 46%);
  display: block;
  color: var(--tg-paragraph-color);
  border-radius: 15px;
  padding: 7px 18px;
}
.comments-text .avatar-name .name .reply-btn:hover {
  background: var(--tg-white);
  color: var(--tg-secondary-color);
  border-color: var(--tg-white);
}
.comments-text .avatar-name .date {
  font-size: 14px;
  display: block;
  line-height: 1;
}
.comments-text p {
  margin-bottom: 0;
}
.latest-comments .children {
  margin: 0 0 0 100px;
}
.comment-respond {
  border: 1px solid rgba(43 44 56 / 46%);
  border-radius: 30px;
  padding: 45px 50px 50px;
  background: var(--tg-secondary-color);
}
.comment-reply-title {
  font-size: 28px;
  margin-bottom: 20px;
  letter-spacing: -0.84px;
}
.comment-notes {
  margin-bottom: 35px;
}
.comment-form .form-grp {
  margin-bottom: 30px;
}
.comment-form .form-grp label {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: var(--tg-white);
  line-height: 1;
  font-family: var(--tg-heading-font-family);
  margin-bottom: 20px;
}
.comment-form .form-grp textarea,
.comment-form .form-grp input {
  width: 100%;
  border: none;
  border-radius: 30px;
  background: rgba(255 255 255 / 7%);
  padding: 12px 20px;
  font-size: 16px;
  color: var(--tg-white);
  height: 50px;
  display: block;
}
.comment-form .form-grp input::placeholder,
.comment-form .form-grp textarea::placeholder {
  color: #aaabb2;
}
.comment-form .form-grp textarea {
  max-height: 155px;
  min-height: 155px;
}
.comment-form .checkbox-grp {
  display: flex;
  align-items: center;
  margin: 40px 0;
}
.comment-form .checkbox-grp input {
  width: 16px;
  margin-right: 9px;
  height: 16px;
  cursor: pointer;
  background: transparent;
  padding: 0;
  border-radius: 2px;
  border: 1px solid #92939e;
  flex: 0 0 auto;
}
.comment-form .checkbox-grp input:focus {
  box-shadow: none;
}
.comment-form .checkbox-grp label {
  font-weight: 400;
  font-size: 14px;
  color: var(--tg-paragraph-color);
  user-select: none;
  margin-bottom: 0;
}
.comment-form .btn:hover {
  background: var(--tg-primary-color);
}
.comments-avatar img {
  border-radius: 50%;
}

/*=============================
	17. Pagination
===============================*/
.pagination-wrap ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px 10px;
}
.pagination-wrap ul li {
  list-style-type: none;
  color: #ffffff;
}
.pagination-wrap ul li.selected {
  background: var(--tg-secondary-color);
  color: #ffffff;
}
.pagination-wrap ul li a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  background: transparent;
  border-radius: 50%;
  font-size: 18px;
  color: var(--tg-white);
  font-weight: 700;
  border: 1px solid rgba(43 44 56 / 46%);
  line-height: 1;
}
.pagination-wrap ul li.active a,
.pagination-wrap ul li a:hover {
  background: var(--tg-secondary-color);
}

/*=============================
	18. Contact
===============================*/
.contact-info-wrap {
  margin-bottom: 110px;
}
.contact-info-item {
  text-align: center;
  background: var(--tg-secondary-color);
  border-radius: 30px;
  margin-bottom: 30px;
  border: 1px solid rgba(43 44 56 / 46%);
  padding: 60px 30px 50px;
}
.contact-info-item .icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #292a37;
  margin: 0 auto 20px;
  font-size: 20px;
  color: var(--tg-white);
  line-height: 1;
}
.contact-info-item .content .title {
  font-size: 20px;
  margin-bottom: 20px;
}
.contact-info-item .content p {
  margin-bottom: 0;
  font-size: 20px;
}
.contact-info-item .content a {
  color: var(--tg-paragraph-color);
}
.contact-info-item .content a:hover {
  color: var(--tg-primary-color);
}
.contact-form-wrap .col-43 {
  width: 43%;
  flex: 0 0 auto;
}
.contact-form-wrap .col-57 {
  width: 57%;
  flex: 0 0 auto;
}
.contact-map {
  width: 100%;
  height: 100%;
}
.contact-map iframe {
  width: 100%;
  height: 100%;
  border-radius: 15px 0 0 15px;
}
.contact-form {
  background: var(--tg-secondary-color);
  border-radius: 0 15px 15px 0;
  padding: 50px 50px;
}
.contact-form .title {
  font-size: 28px;
  margin-bottom: 30px;
  font-weight: 500;
}
.contact-form .row {
  --bs-gutter-x: 20px;
}
.contact-form .form-grp {
  margin-bottom: 20px;
}
.contact-form .form-grp textarea,
.contact-form .form-grp input {
  width: 100%;
  background: rgba(255 255 255 / 7%);
  display: block;
  border: none;
  padding: 15px 15px;
  border-radius: 30px;
  color: var(--tg-white);
  line-height: 1;
  height: 50px;
}
.contact-form .form-grp textarea::placeholder,
.contact-form .form-grp input::placeholder {
  color: #aaabb2;
}
.contact-form .form-grp textarea {
  min-height: 160px;
  max-height: 160px;
}
.contact-form .btn:hover {
  background: var(--tg-primary-color);
}
.ajax-response.error {
  color: red;
  margin-top: 20px;
}
.ajax-response.success {
  color: green;
}

/*=============================
	19. Footer
===============================*/
.footer-bg {
  background-size: cover;
  background-position: center;
  border-radius: 20px 20px 0 0;
  position: relative;
  padding: 350px 0 0;
  margin: -350px 20px 0;
  overflow: hidden;
}
.footer-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 65.23%
  );
  z-index: -2;
  transform: rotate(180deg);
}
.footer-top {
  padding: 110px 0 30px;
  z-index: 2;
  position: relative;
}
.footer-widget {
  margin-bottom: 40px;
}
.footer-widget .fw-title {
  margin-bottom: 25px;
  font-size: 18px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}
.footer-link .list-wrap li {
  margin-bottom: 15px;
}
.footer-link .list-wrap li:last-child {
  margin-bottom: 0;
}
.footer-link .list-wrap li a {
  color: #cbd0d4;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
  position: relative;
}
.footer-link .list-wrap li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: var(--tg-white);
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transition: transform 0.4s cubic-bezier(1, 0, 0, 1);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}
.footer-link .list-wrap li a:hover::before {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.footer-newsletter p {
  margin-bottom: 60px;
  font-size: 15px;
  color: #cbd0d4;
  font-weight: 500;
}
.footer-newsletter form {
  position: relative;
}
.footer-newsletter form input {
  width: 100%;
  border: none;
  background: var(--tg-secondary-color);
  padding: 24px 80px 24px 18px;
  font-size: 15px;
  color: var(--tg-white);
  font-weight: 400;
  border-radius: 10px;
  height: 72px;
}
.footer-newsletter form input::placeholder {
  font-size: 15px;
  color: #aaabb2;
  font-weight: 400;
}
.footer-newsletter form button {
  width: 65px;
  height: 72px;
  border: none;
  padding: 0;
  background: var(--tg-white);
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 24px;
  color: var(--tg-secondary-color);
}
.footer-newsletter form button:hover {
  color: var(--tg-white);
  background: var(--tg-primary-color);
}
.footer-bottom {
  padding: 30px 0;
  position: relative;
}
.footer-bottom::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    225deg,
    var(--tg-black) 0%,
    rgba(255, 255, 255, 0.45) 54.98%,
    rgba(1, 3, 20, 0.99) 100%
  );
}
.copyright-text p {
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
  font-size: 500;
  color: var(--tg-white);
}
.footer-shape-wrap img {
  position: absolute;
}
.footer-shape-wrap img:nth-child(1) {
  left: 0;
  top: 35%;
}
.footer-shape-wrap img:nth-child(2) {
  right: 5%;
  top: 43%;
}

/*=============================
	20. Preloader
===============================*/
#preloader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-black);
  z-index: 999;
  width: 100%;
  height: 100%;
}
.spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}
.spinner > div {
  background-color: var(--tg-primary-color);
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
p.form_error {
  color: red;
  padding: 0;
  margin-bottom: 20px;
  text-align: left;
  margin-top: 5px;
}

/* error page */

.eg-error__area {
  background-color: var(--tg-black);
  padding: 290px 0 180px;
}
.eg-error__title {
  font-size: 320px;
  line-height: 0.8;
  color: #ffffff;
  margin: 0 0 40px;
}
.eg-error__content p {
  font-size: 36px;
  line-height: 1.18;
  margin: 0 0 35px;
  color: #ffffff;
}
.eg-error__content .eg-btn {
  background: #222222;
}

/*----------------------------------------*/
/* Login css
/*----------------------------------------*/
.eg-login__wrapper {
  box-shadow: 0px 30px 70px rgba(1, 15, 28, 0.1);
  padding: 50px 60px 70px;
  background: var(--tg-secondary-color);
  border-radius: 15px;
}
.eg-login__title {
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 4px;
}
.eg-login__top p a {
  display: inline-block;
  position: relative;
  color: var(--tg-primary-color);
}
.eg-login__option-item {
  margin-bottom: 10px;
}
.eg-login__option-item a {
  display: inline-block;
  width: 98px;
  height: 56px;
  line-height: 54px;
  text-align: center;
  border: 1px solid rgba(43, 44, 56, 0.86);
  font-size: 16px;
  width: 100%;
  border-radius: 10px;
  color: var(--tg-body-font-color);
}
.eg-login__bottom .btn {
  text-align: center;
  display: inline-block;
}
.eg-login__bottom .btn:hover {
  background: var(--tg-primary-color);
}
.eg-login__option-item a img {
  margin-right: 7px;
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
}
.eg-login__option-item a:hover {
  color: var(--tg-primary-color);
  border-color: var(--tg-primary-color);
}
.eg-login__mail {
  position: relative;
  z-index: 1;
}
.eg-login__mail::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  bottom: 12px;
  width: 100%;
  height: 1px;
  background-color: rgba(43, 44, 56, 0.86);
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
  z-index: -1;
}
.eg-login__mail p {
  font-size: 15px;
  color: var(--tg-body-font-color);
  margin-bottom: 0;
  padding: 0 20px;
  position: relative;
  display: inline-block;
  background-color: var(--tg-secondary-color);
}
.eg-login__input-wrapper {
  margin-bottom: 14px;
}
.eg-login__input-box:not(:last-child) {
  margin-bottom: 34px;
}
.eg-password-show {
  position: relative;
}
.eg-login__input input {
  width: 100%;
  border: none;
  font-size: 15px;
  font-weight: 400;
  display: block;
  padding: 14px 20px;
  color: var(--tg-white);
  border-radius: 5px;
  height: 60px;
  transition: 0.3s linear;
  background: hsla(0, 0%, 100%, 0.07);
}
.eg-login__input input:focus {
  border-color: var(--tg-primary-color);
}
.eg-login__input input#eg_password {
  padding-right: 60px;
}
.eg-login__input label {
  margin-bottom: 10px;
}
.eg-login__input-eye {
  position: absolute;
  right: 26px;
  top: 50%;
  transform: translateY(-50%);
}
.eg-login__input-eye span {
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}
.eg-login__input-eye:hover {
  cursor: pointer;
}
.eg-login__input-eye span svg {
  /* fill: var(--tg-white); */
}
.eg-login__option p {
  text-align: center;
  margin: 10px 0 0;
}
.eg-login__option p a {
  display: inline-block;
  color: var(--tg-primary-color);
}
.eg-login__remeber input {
  display: none;
}
.eg-login__remeber input:checked ~ label::after {
  background-color: var(--tg-primary-color);
  border-color: var(--tg-primary-color);
}
.eg-login__remeber input:checked ~ label::before {
  visibility: visible;
  opacity: 1;
}
.eg-login__remeber label {
  font-size: 15px;
  color: var(--tg-body-font-color);
  position: relative;
  padding-left: 26px;
  z-index: 1;
}
.eg-login__remeber label::after {
  position: absolute;
  content: "";
  top: 4px;
  left: 0;
  width: 18px;
  height: 18px;
  line-height: 16px;
  text-align: center;
  border: 1px solid #c3c7c9;
  z-index: -1;
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}
.eg-login__remeber label::before {
  position: absolute;
  /* content: url("/assets/img/icons/check.svg"); */
  top: 4px;
  left: 0;
  width: 18px;
  height: 18px;
  line-height: 16px;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  color: #ffffff;
  -webkit-transition: all 0.3s 0s ease-out;
  -moz-transition: all 0.3s 0s ease-out;
  -ms-transition: all 0.3s 0s ease-out;
  -o-transition: all 0.3s 0s ease-out;
  transition: all 0.3s 0s ease-out;
}
.eg-login__remeber label a:hover {
  color: var(--tg-primary-color);
}
.eg-login__remeber label:hover {
  cursor: pointer;
}
.eg-login__forgot a {
  font-weight: 400;
  font-size: 15px;
  color: var(--tg-primary-color);
  display: inline-block;
}

.how-it-works-wrapper {
  display: flex;
  background-color: var(--tg-black);
  /* margin: 24px -16px 0px; */
}

.step-item {
  flex: 1 1 0%;
  padding: 0px 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
}

.step-item:first-child::after {
  width: 50%;
  left: 50%;
}

.step-item:last-child::after {
  width: 50%;
}

.step-item::after {
  content: "";
  position: absolute;
  top: 104px;
  left: 0px;
  width: 100%;
  height: 1px;
  background-color: rgb(0, 163, 255);
}

.step-item-title {
  font-weight: 700;
  font-size: 26px;
  line-height: 38px;
  text-align: center;
  color: white;
}

.step-item-icon-wrapper {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 84px;
  height: 84px;
  margin-top: 24px;
  background-color: rgb(255, 255, 255);
  border-radius: 100%;
  border: 1px solid rgb(0, 163, 255);
  z-index: 1;
}

.step-item-content-wrapper {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  flex: 1 1 0%;
  margin-top: 24px;
}

.step-item-content-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: white;
  text-align: center;
}

.step-item-content-text {
  margin-top: 6px;
  font-size: 14px;
  line-height: 24px;
  color: rgb(122, 138, 160);
  text-align: center;
}

.lang-menu-button {
  background-color: white !important;
  border-radius: 20px !important;
  color: #010314 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-family: "Outfit", sans-serif !important;
  text-transform: none !important;
}

.lang-menu-item {
  font-family: "Outfit", sans-serif !important;
}

.generate-code-button {
  background-color: white !important;
  border-radius: 20px !important;
  color: #010314 !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  padding: 3px 10px !important;
  font-family: "Outfit", sans-serif !important;
  text-transform: none !important;
  margin-left: auto !important;
  width: 100px !important;
}

.region-modal-text {
  font-family: "Outfit", sans-serif !important;
  font-weight: 700 !important;
}

.region-modal-button {
  background-color: #44a08d !important;
  border-radius: 20px !important;
  color: white !important;
  font-size: 18x !important;
  font-weight: 700 !important;
  padding: 3px 10px !important;
  font-family: "Outfit", sans-serif !important;
  text-transform: none !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 10px !important;
}

.footer-wrapper {
  width: 100%;
  display: flex;
  padding: 10px 20px;
}

.footer-wrapper-border {
  content: var(--tw-content);
  position: absolute;
  left: 2rem;
  /* bottom: 0; */
  height: 1px;
  width: calc(100% - 64px);
  background-image: radial-gradient(
    100% 628.91% at 95.63% 10.42%,
    rgba(230, 218, 254, 0) 0,
    #e6dafe 30.71%,
    #e6dafe 71.52%,
    rgba(230, 218, 254, 0) 100%
  );
  opacity: 0.6;
}

.footer-sns-wrapper {
  margin-left: auto !important;
  display: flex;
}

.footer-sns-item {
  margin: auto 0px auto 30px;
}

.footer-sns-item:first-child {
  margin: auto 0px auto 0px;
}

.footer-sns-item img {
  width: 30px;
  height: 30px;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.full-height {
  min-height: calc(100vh - 80px);
}
